import i18n from '@/i18n/index';

export default {
  sunbeds: {
    models: [
      {
        id: 'luxura_jewel',
        name: i18n.tu('sunbeds.models.luxura_jewel.name') || 'luxura_jewel',
        types: [
          {
            id: 'EQ45',
            name: i18n.tu('sunbeds.models.luxura_jewel.types.EQ45') || 'EQ45',
            colors: [
              {
                id: 'sunbed',
                name: 'Sunbed color',
                options: ['Crystal White'],
              },
            ],
            options: [
              'UV-LED Boss-Booster',
              'UV-LED Shoulder Tanner',
              'UV-LED Face Tanner',
              'SoundAround Plus',
              'Wireless Phone Charger',
            ],
          },
          // {
          //   id: 'EQ55',
          //   name: i18n.tu('sunbeds.models.luxura_jewel.types.EQ55') || 'EQ55',
          //   colors: [
          //     {
          //       id: 'sunbed',
          //       name: 'Sunbed color',
          //       options: ['Crystal White'],
          //     },
          //   ],
          //   options: [
          //     'UV-LED Boss-Booster',
          //     'UV-LED Shoulder Tanner',
          //     'UV-LED Face Tanner',
          //     'SoundAround Plus',
          //     'Wireless Phone Charger',
          //   ],
          // },
        ],
        filters: [],
        options: [
          'UV-LED Boss-Booster',
          'UV-LED Shoulder Tanner',
          'UV-LED Face Tanner',
          'SoundAround Plus',
          'Wireless Phone Charger',
          'Climate Control',
          'Sens',
          'Compact Air Exhaust',
          'Air Exhaust Tower',
          'Air Exhaust Tower system with Warm Air Return System',
        ],
      },
      {
        id: 'luxura_vegaz_9200',
        name: i18n.tu('sunbeds.models.luxura_vegaz_9200.name') || 'luxura_vegaz_9200',
        types: [
          {
            id: '9200_balance',
            name: i18n.tu('sunbeds.models.luxura_vegaz_9200.types.9200_balance') || '9200_balance',
            colors: [
              {
                id: 'sunbed',
                name: 'Sunbed color',
                options: ['Elite Gold', 'Dynamic Red', 'Stylish Silver'],
              },
            ],
            options: [
              'Shoulder tanner + SoundAround Plus',
            ],
          },
          {
            id: '9200_intelligent',
            name: i18n.tu('sunbeds.models.luxura_vegaz_9200.types.9200_intelligent') || '9200_intelligent',
            colors: [
              {
                id: 'sunbed',
                name: 'Sunbed color',
                options: ['Elite Gold', 'Dynamic Red', 'Stylish Silver'],
              },
            ],
            options: [
              'Shoulder tanner + SoundAround Plus',
            ],
          },
          {
            id: '9200_highbrid',
            name: i18n.tu('sunbeds.models.luxura_vegaz_9200.types.9200_highbrid') || '9200_highbrid',
            colors: [
              {
                id: 'sunbed',
                name: 'Sunbed color',
                options: ['Elite Gold', 'Dynamic Red', 'Stylish Silver'],
              },
            ],
            options: [
              'UV-LED tanning booster',
              'Shoulder tanner + SoundAround Plus',
            ],
          },
        ],
        filters: [
          'BPS',
          'IPS',
          'HPS',
          'Highbrid',
        ],
        options: [
          'UV-LED tanning booster',
          'Qsens & Xsens III',
          'Shoulder tanner + SoundAround Plus',
          'Climate Control',
          'Compact air exhaust',
          'Air exhaust tower',
          'Air exhaust tower system with warm air return system',
        ],
      },
      {
        id: 'luxura_vegaz_8200',
        name: i18n.tu('sunbeds.models.luxura_vegaz_8200.name') || 'luxura_vegaz_8200',
        types: [
          {
            id: '8200_balance',
            name: i18n.tu('sunbeds.models.luxura_vegaz_8200.types.8200_balance') || '8200_balance',
            colors: [
              {
                id: 'sunbed',
                name: 'Sunbed color',
                options: ['Elite Gold', 'Dynamic Red', 'Stylish Silver'],
              },
            ],
            options: [],
          },
          {
            id: '8200_intelligent',
            name: i18n.tu('sunbeds.models.luxura_vegaz_8200.types.8200_intelligent') || '8200_intelligent',
            colors: [
              {
                id: 'sunbed',
                name: 'Sunbed color',
                options: ['Elite Gold', 'Dynamic Red', 'Stylish Silver'],
              },
            ],
            options: ['Shoulder tanner + SoundAround Plus'],
          },
          {
            id: '8200_highbrid',
            name: i18n.tu('sunbeds.models.luxura_vegaz_8200.types.8200_highbrid') || '8200_highbrid',
            colors: [
              {
                id: 'sunbed',
                name: 'Sunbed color',
                options: ['Elite Gold', 'Dynamic Red', 'Stylish Silver'],
              },
            ],
            options: ['UV-LED tanning booster', 'Shoulder tanner + SoundAround Plus'],
          },
        ],
        filters: [
          'BPS',
          'IPS',
          'HPS',
          'Highbrid',
        ],
        options: [
          'Luxury Front Panel',
          'UV-LED tanning booster',
          'Sound Plus',
          'Shoulder tanner + SoundAround Plus',
          'Qsens & Xsens III',
          'Climate Control',
          'Compact air exhaust',
          'Air exhaust tower',
          'Air exhaust tower system with warm air return system',
        ],
      },
      {
        id: 'luxura_x7',
        name: i18n.tu('sunbeds.models.luxura_x7.name') || 'luxura_x7',
        types: [
          {
            id: '38_sli',
            name: i18n.tu('sunbeds.models.luxura_x7.types.38_sli') || '38_sli',
            colors: [
              {
                id: 'sunbed',
                name: 'Sunbed color',
                options: ['Crystal White'],
              },
            ],
            options: ['Inox steel polished Decoshield & Brandshield'],
          },
          {
            id: '38_sli_intensive',
            name: i18n.tu('sunbeds.models.luxura_x7.types.38_sli_intensive') || '38_sli_intensive',
            colors: [
              {
                id: 'sunbed',
                name: 'Sunbed color',
                options: ['Crystal White'],
              },
            ],
            options: ['Inox steel polished Decoshield & Brandshield'],
          },
          {
            id: '38_sli_high_intensive',
            name: i18n.tu('sunbeds.models.luxura_x7.types.38_sli_high_intensive') || '38_sli_high_intensive',
            colors: [
              {
                id: 'sunbed',
                name: 'Sunbed color',
                options: ['Crystal White'],
              },
            ],
            options: ['Inox steel polished Decoshield & Brandshield'],
          },
          {
            id: '38_sli_balance',
            name: i18n.tu('sunbeds.models.luxura_x7.types.38_sli_balance') || '38_sli_balance',
            colors: [
              {
                id: 'sunbed',
                name: 'Sunbed color',
                options: ['Crystal White'],
              },
            ],
            options: ['Pre-wiring for options after market 38', 'SoundAround Plus', 'Inox steel polished Decoshield & Brandshield'],
          },
          {
            id: '38_sli_intelligent',
            name: i18n.tu('sunbeds.models.luxura_x7.types.38_sli_intelligent') || '38_sli_intelligent',
            colors: [
              {
                id: 'sunbed',
                name: 'Sunbed color',
                options: ['Crystal White'],
              },
            ],
            options: ['Pre-wiring for options after market 38', 'SoundAround Plus', 'Inox steel polished Decoshield & Brandshield'],
          },
          {
            id: '38_highbrid',
            name: i18n.tu('sunbeds.models.luxura_x7.types.38_highbrid') || '38_highbrid',
            colors: [
              {
                id: 'sunbed',
                name: 'Sunbed color',
                options: ['Crystal White'],
              },
            ],
            options: ['Pre-wiring for options after market 38', 'SoundAround Plus', 'Inox steel polished Decoshield & Brandshield'],
          },
          {
            id: '42_sli',
            name: i18n.tu('sunbeds.models.luxura_x7.types.42_sli') || '42_sli',
            colors: [
              {
                id: 'sunbed',
                name: 'Sunbed color',
                options: ['Crystal White'],
              },
            ],
            options: ['Pre-wiring for options after market 38', 'SoundAround Plus', 'Inox steel polished Decoshield & Brandshield'],
          },
          {
            id: '42_sli_intensive',
            name: i18n.tu('sunbeds.models.luxura_x7.types.42_sli_intensive') || '42_sli_intensive',
            colors: [
              {
                id: 'sunbed',
                name: 'Sunbed color',
                options: ['Crystal White'],
              },
            ],
            options: ['Pre-wiring for options after market 38', 'SoundAround Plus', 'Inox steel polished Decoshield & Brandshield'],
          },
          {
            id: '42_sli_high_intensive',
            name: i18n.tu('sunbeds.models.luxura_x7.types.42_sli_high_intensive') || '42_sli_high_intensive',
            colors: [
              {
                id: 'sunbed',
                name: 'Sunbed color',
                options: ['Crystal White'],
              },
            ],
            options: ['Pre-wiring for options after market 38', 'SoundAround Plus', 'Inox steel polished Decoshield & Brandshield'],
          },
          {
            id: '42_sli_balance',
            name: i18n.tu('sunbeds.models.luxura_x7.types.42_sli_balance') || '42_sli_balance',
            colors: [
              {
                id: 'sunbed',
                name: 'Sunbed color',
                options: ['Crystal White'],
              },
            ],
            options: ['Pre-wiring for options after market 38', 'SoundAround Plus', 'Inox steel polished Decoshield & Brandshield'],
          },
          {
            id: '42_sli_intelligent',
            name: i18n.tu('sunbeds.models.luxura_x7.types.42_sli_intelligent') || '42_sli_intelligent',
            colors: [
              {
                id: 'sunbed',
                name: 'Sunbed color',
                options: ['Crystal White'],
              },
            ],
            options: ['Pre-wiring for options after market 38', 'SoundAround Plus', 'Inox steel polished Decoshield & Brandshield'],
          },
          {
            id: '42_highbrid',
            name: i18n.tu('sunbeds.models.luxura_x7.types.42_highbrid') || '42_highbrid',
            colors: [
              {
                id: 'sunbed',
                name: 'Sunbed color',
                options: ['Crystal White'],
              },
            ],
            options: ['Pre-wiring for options after market 38', 'SoundAround Plus', 'Inox steel polished Decoshield & Brandshield'],
          },
        ],
        filters: [
          'HPS',
          'BPS',
          'Highbrid',
        ],
        options: [
          'Airco',
          'Ambient FlowLight Eyeliners',
          'Pre-wiring for options after market 38',
          'Inox steel polished Decoshield & Brandshield',
          'SoundAround Plus',
          'Xsens III',
          'Qsens',
        ],
      },
      {
        id: 'luxura_x5',
        name: i18n.tu('sunbeds.models.luxura_x5.name') || 'luxura_x5',
        types: [
          {
            id: '34_sli',
            name: i18n.tu('sunbeds.models.luxura_x5.types.34_sli') || '34_sli',
            colors: [
              {
                id: 'sunbed',
                name: 'Sunbed color',
                options: ['Crystal White'],
              },
            ],
          },
          {
            id: '34_sli_intensive',
            name: i18n.tu('sunbeds.models.luxura_x5.types.34_sli_intensive') || '34_sli_intensive',
            colors: [
              {
                id: 'sunbed',
                name: 'Sunbed color',
                options: ['Crystal White'],
              },
            ],
          },
          {
            id: '34_sli_high_intensive',
            name: i18n.tu('sunbeds.models.luxura_x5.types.34_sli_high_intensive') || '34_sli_high_intensive',
            colors: [
              {
                id: 'sunbed',
                name: 'Sunbed color',
                options: ['Crystal White'],
              },
            ],
          },
          {
            id: '34_sli_balance',
            name: i18n.tu('sunbeds.models.luxura_x5.types.34_sli_balance') || '34_sli_balance',
            colors: [
              {
                id: 'sunbed',
                name: 'Sunbed color',
                options: ['Crystal White'],
              },
            ],
          },
          {
            id: '38_sli_high_intensive',
            name: i18n.tu('sunbeds.models.luxura_x5.types.38_sli_high_intensive') || '38_sli_high_intensive',
            colors: [{
              id: 'sunbed',
              name: 'Sunbed color',
              options: ['Crystal White'],
            }],
          },
          {
            id: 'C700_seecret',
            name: i18n.tu('sunbeds.models.luxura_x5.types.C700_seecret') || 'C700_seecret',
            filter: 'BPS',
            options: ['Ambient FlowLight Centre line', 'Ambient FlowLight Connect line', 'Luxury Front Panel with Brandshield and Ambient FlowLight'],
          },
        ],
        filters: [
          'HPS',
          'BPS',
          'Highbrid',
        ],
        options: [
          'Airco',
          'Ambient FlowLight Centre line',
          'Ambient FlowLight Connect line',
          'Luxury Front Panel with Brandshield and Ambient FlowLight',
          'Pre-wiring for options after market',
          'SoundAround Plus',
          'Xsens III',
          'Qsens',
        ],
      },

      {
        id: 'luxura_v6',
        name: i18n.tu('sunbeds.models.luxura_v6.name') || 'luxura_v6',
        types: [
          {
            id: '42_xl',
            name: i18n.tu('sunbeds.models.luxura_v6.types.42_xl') || '42_xl',
            colors: [
              {
                id: 'sunbed',
                name: 'Sunbed color',
                options: ['Crystal White'],
              },
            ],
          },
          {
            id: '44xl_balance',
            name: i18n.tu('sunbeds.models.luxura_v6.types.44xl_balance') || '44xl_balance',
            colors: [
              {
                id: 'sunbed',
                name: 'Sunbed color',
                options: ['Crystal White'],
              },
            ],
          },
          {
            id: '44xl_balance_ultra',
            name: i18n.tu('sunbeds.models.luxura_v6.types.44xl_balance_ultra') || '44xl_balance_ultra',
            colors: [
              {
                id: 'sunbed',
                name: 'Sunbed color',
                options: ['Crystal White'],
              },
            ],
          },
          {
            id: '48xl_balance',
            name: i18n.tu('sunbeds.models.luxura_v6.types.48xl_balance') || '48xl_balance',
            colors: [
              {
                id: 'sunbed',
                name: 'Sunbed color',
                options: ['Crystal White'],
              },
            ],
          },
          {
            id: '48xl_balance_ultra',
            name: i18n.tu('sunbeds.models.luxura_v6.types.48xl_balance_ultra') || '48xl_balance_ultra',
            colors: [
              {
                id: 'sunbed',
                name: 'Sunbed color',
                options: ['Crystal White'],
              },
            ],
          },
        ],
        options: [
          'Twin Colour Gold',
          'SoundAround Plus',
          'Qsens',
          'Xsens',
        ],
      },

      {
        id: 'luxura_v8',
        name: i18n.tu('sunbeds.models.luxura_v8.name') || 'luxura_v8',
        types: [
          {
            id: '48xl_balance',
            name: i18n.tu('sunbeds.models.luxura_v8.types.48xl_balance') || '48xl_balance',
            colors: [
              {
                id: 'sunbed',
                name: 'Sunbed color',
                options: ['Crystal White'],
              },
            ],
          },
          {
            id: '48xl_balance_ultra',
            name: i18n.tu('sunbeds.models.luxura_v8.types.48xl_balance_ultra') || '48xl_balance_ultra',
            colors: [
              {
                id: 'sunbed',
                name: 'Sunbed color',
                options: ['Crystal White'],
              },
            ],
          },
          {
            id: '48xl_intelligent',
            name: i18n.tu('sunbeds.models.luxura_v8.types.48xl_intelligent') || '48xl_intelligent',
            colors: [
              {
                id: 'sunbed',
                name: 'Sunbed color',
                options: ['Crystal White'],
              },
            ],
          },
          {
            id: '48xl_highbrid',
            name: i18n.tu('sunbeds.models.luxura_v8.types.48xl_highbrid') || '48xl_highbrid',
            colors: [
              {
                id: 'sunbed',
                name: 'Sunbed color',
                options: ['Crystal White'],
              },
            ],
          },
        ],
        options: [
          'SoundAround Plus',
          'Walnut Wooden Handle',
          'Qsens',
          'Xsens',
        ],
      },
    ],
  },
};
